import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV53() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.3.22' id='estabilizacao-5-3-22'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Ao adicionar uma medição anterior através do cartão lateral do SOAP, o peso mais recente do cidadão será
            considerado na modal de Prescrição de medicamentos.
          </Text>
          <Text component='li'>
            Inclusão de uma nova regra para o novo termo de responsabilidade/esclarecimento da Prescrição de
            medicamentos, passando a contemplar as opções de identidade de gênero e sexo indeterminado.
          </Text>
          <Text component='li'>
            Inclusão de novos Grupos de atendimentos disponíveis para seleção ao registrar aplicação de imunobiológico.
          </Text>
          <Text component='li'>
            Inclusão de novo imunobiológico COVID-19 SERUM/ZALIKA e atualização de diversas regras de aplicação para
            outros imunobiológicos contra COVID-19.
          </Text>
          <Text component='li'>
            Correção do cenário em que ocorria chave duplicada em relação aos problemas e condições recebidos de bases
            unificadas ao tentar finalizar um atendimento.
          </Text>
          <Text component='li'>
            Inclusão dos campos de localização e código do domicílio na tabela de acompanhamento dos cidadãos
            vinculados.
          </Text>
          <Text component='li'>
            Correção de cenário em que os campos de data inicial e final, CID e CIAP dos atendimentos não estavam sendo
            processados corretamente, impactando relatórios e o histórico de atendimentos.
          </Text>
          <Text component='li'>
            Ajustes no módulo de importação de CNES, onde, em algumas instalações, não estava sendo possível importar o
            arquivo.
          </Text>
          <Text component='li'>
            Correção de cenário onde uma página em branco era gerada ao imprimir medicamentos com preenchimento manual.
          </Text>
          <Text component='li'>Correção de erro que impedia a atualização do sistema em algumas instalações.</Text>
          <Text component='li'>
            Ajuste no campo Desfecho da escuta inicial para manter a opção "Adicionar cidadão na lista de atendimentos"
            como padrão já pré-selecionado ao finalizar a escuta.
          </Text>
          <Text component='li'>
            Correção de cenário onde algumas famílias estavam sendo duplicadas ao se atualizar as Fichas de cadastro
            domiciliar e territoriais correspondentes a elas.
          </Text>
          <Text component='li'>
            Correção na filtragem do campo "Problemas / Condições avaliadas - Outros CIAP 2" no módulo de Relatório de
            atendimento individual.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.21' id='estabilizacao-5-3-21'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro relacionado ao motivo do desfecho no envio de registros para RNDS.
          </Text>
          <Text component='li'>Correção de cenário que impedia a atualização do sistema em algumas instalações.</Text>
          <Text component='li'>
            Adicionada a possibilidade de ver as doses fracionadas de medicamentos por turno no Histórico clínico do
            cidadão de Atendimentos odontológicos.
          </Text>
          <Text component='li'>
            Correção de cenário em que cidadãos sem informações de doses eram exibidos incorretamente na listagem da
            Busca Ativa de Vacinação.
          </Text>
          <Text component='li'>
            Adicionada mensagem informativa na Busca ativa de vacinação, onde as doses de reforço (REF) do
            imunobiológico VIP serão exibidas como atrasadas, mesmo que as doses R1 e R2 do imunobiológico VOP já tenham
            sido aplicadas.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.20' id='estabilizacao-5-3-20'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Atualização da tela de links úteis e inclusão do link do Educa e-SUS APS.</Text>
          <Text component='li'>
            Implementada a funcionalidade de reimpressão de receitas médicas no cartão de Medicamentos, disponível na
            Folha de rosto e no Atendimento.
          </Text>
          <Text component='li'>
            Inclusão do novo termo de responsabilidade na Prescrição de medicamentos com Talidomida, para cidadãos do
            sexo feminino e menores de 55 anos.
          </Text>
          <Text component='li'>
            Retirada do campo subjetivo enviado para a RNDS no Registro de atendimento clínico.
          </Text>
          <Text component='li'>
            Atualização para melhorar a performance dos relatórios gerenciais quando o banco de dados RocksDB está
            habilitado na instalação.
          </Text>
          <Text component='li'>
            Alteração da terminologia "Idoso" ou "Idosos" para "Pessoa idosa" ou "Pessoas idosas" respectivamente.
          </Text>
          <Text component='li'>
            Correção de cenário onde o uso de nome social do profissional não era aplicado em alguns agendamentos
            futuros.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.3.19' id='estabilizacao-5-3-19'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Atualizações globais no sistema para assegurar compatibilidade com a nova opção de sexo "Indeterminado".
          </Text>
          <Text component='li'>
            Agora é possível visualizar doses fracionadas de medicamentos por turno no histórico clínico do cidadão para
            atendimentos individuais.
          </Text>
          <Text component='li'>
            Ajustes no histórico clínico para exibir corretamente o nome social dos profissionais.
          </Text>
          <Text component='li'>
            Correção no campo de modalidade assistencial transmitido para a RNDS no Registro de Atendimento Clínico.
          </Text>
          <Text component='li'>Inclusão de um link para a página de "Links Úteis" na tela inicial do sistema.</Text>
          <Text component='li'>
            Implementação de restrição para impedir a seleção da opção "Manter cidadão na lista de atendimento" em
            registros de atendimentos realizados em datas anteriores.
          </Text>
          <Text component='li'>Inclusão do peso do cidadão na modal de prescrição de medicamentos.</Text>
          <Text component='li'>
            Adição de alerta para informar sobre alergias recentemente registradas, garantindo que o profissional
            visualize avisos de alergia durante o atendimento.
          </Text>
          <Text component='li'>
            As quebras de linha inseridas no campo de recomendações agora são exibidas corretamente na impressão das
            prescrições de medicamentos.
          </Text>
          <Text component='li'>
            Atualização das regras para registro da aplicação das vacinas VIP (código 22), dT (código 25), VOP (código
            28) e dTpa (código 57), com os ajustes necessários no calendário vacinal infantil.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
