/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, FormControl, Grid, Heading, Text, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { CheckboxField } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
  isCiap,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { EquipeResponsavelField } from 'components/form/field/select/EquipeResponsavelSelectField/EquipeResponsavelField'
import {
  GrupoCondicaoSelectField,
  GrupoCondicaoSelectModel,
} from 'components/form/field/select/GrupoCondicaoSelectField'
import { MicroareaSelectField } from 'components/form/field/select/MicroareaSelectField/MicroareaSelectField'
import { useFlags } from 'config/useFlagsContext'
import { FormApi } from 'final-form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, useCallback } from 'react'
import { FormRenderProps } from 'react-final-form'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'

import { FaixaEtariaFilterGroup } from '../components/FaixaEtariaFilterGroup'
import { IvcfFilterGroup } from '../components/IvcfFilterGroup'
import { SexoIdentidadeGeneroButtonGroup } from '../components/SexoIdentidadeGeneroButtonGroup'
import GrupoCondicaoBuscaRapida from './components/GrupoCondicaoBuscaRapida'
import { AcompanhamentoCondicaoSaudeFormModel, condicaoToFormRecord } from './model-acompanhamentoCondicaoSaude'
import { SearchFooter } from './SearchFooter'

interface AcompanhamentoCondicaoSaudeFormProps extends FormRenderProps<AcompanhamentoCondicaoSaudeFormModel> {
  setHasIvcfFilter: (hasIvcf: boolean) => void
}

const path = metaPath<AcompanhamentoCondicaoSaudeFormModel>()

const onClearField = (form: FormApi<AcompanhamentoCondicaoSaudeFormModel>, field: string) => {
  form.change(field, undefined)
  form.blur(field)
}

export function AcompanhamentoCondicaoSaudeForm(props: AcompanhamentoCondicaoSaudeFormProps) {
  const { setHasIvcfFilter, values, form, handleSubmit } = props
  const { IVCF_ACOMPANHAMENTO_CONDICOES_ENABLED } = useFlags()
  const { acesso, hasAuthorization } = useAcessoLotacaoOrEstagio()
  const { analytics } = useFirebase()

  const isCoordenador = hasAuthorization(Permissions.acompanhamentos.condicoesDeSaude.todasAsEquipes)

  const canCheckApenasProblemasCondicoesAtivas = isEmpty(values.grupoCondicao) && isEmpty(values.problemasCondicoes)

  const now = new Date()

  const handleEquipeResponsavelChange = () => {
    form.change(path.microareas.absolutePath(), null)
  }

  form.change('unidadeResponsavelId', acesso?.unidadeSaude?.id)
  form.change('equipeResponsavelId', acesso?.equipe?.id)
  form.change('isCoordenador', isCoordenador)

  const handleOnSubmit = () => {
    const hasPeriodoUltimoAtendimento =
      values?.periodoUltimoAtendimento?.startDate || values?.periodoUltimoAtendimento?.endDate

    const hasCiap = !isEmpty(values?.problemasCondicoes?.find((item) => isCiap(item)))
    const hasCid = !isEmpty(values?.problemasCondicoes?.find((item) => !isCiap(item)))

    !isEmpty(values.isDiabetesCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_diabetes')
    !isEmpty(values.isHipertensaoCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_hipertensao')
    !isEmpty(values.isObesidadeCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_obesidade')
    !isEmpty(values.isGravidezCheck) && analytics.logEvent('buscar_acomp_condicoes_saude_with_gravidez')
    !isEmpty(values.grupoCondicao) && analytics.logEvent('buscar_acomp_condicoes_saude_with_grupo_condicao')
    hasCiap && analytics.logEvent('buscar_acomp_condicoes_saude_with_ciap')
    hasCid && analytics.logEvent('buscar_acomp_condicoes_saude_with_cid')
    hasPeriodoUltimoAtendimento && analytics.logEvent('buscar_acomp_condicoes_saude_with_periodo_ultimo_atend')

    values.ativoListaProblema
      ? analytics.logEvent('buscar_acomp_condicoes_saude_with_condicoes_ativas')
      : analytics.logEvent('buscar_acomp_condicoes_saude_with_condicoes_ativas_e_inativas')

    setHasIvcfFilter(values.isBotaoIvcfAtivo)

    handleSubmit()
  }

  const handleClearCheckbox = useCallback(
    (numberOfGroups: number) => {
      numberOfGroups === 0 &&
        canCheckApenasProblemasCondicoesAtivas &&
        form.change(path.ativoListaProblema.absolutePath(), false)
    },
    [canCheckApenasProblemasCondicoesAtivas, form]
  )

  const handleOnChangeGrupoCond = (grupos: GrupoCondicaoSelectModel[]) => {
    if (!grupos?.length && !values.problemasCondicoes?.length) {
      form.change(path.ativoListaProblema.absolutePath(), false)
    }
    Object.entries(condicaoToFormRecord).forEach(([condicao, formKey]) => {
      const isConditionPresent = grupos.some((item) => item.grupoCondicao.toString() === condicao)
      if (isConditionPresent !== values[formKey]) {
        form.change(path[formKey].absolutePath(), isConditionPresent)
      }
    })
  }

  const handleChangeCiapCid = (ciapCidList: CiapCidSelectModel[]) => {
    if (isEmpty(ciapCidList) && isEmpty(values.grupoCondicao)) {
      form.change(path.ativoListaProblema.absolutePath(), false)
    }
  }

  return (
    <VFlow vSpacing={2}>
      <Grid wrap>
        <Cell size={3} style={styles.cell}>
          <FormControl label='Unidade responsável'>
            <Text>{acesso?.unidadeSaude?.nome.titleCase()}</Text>
          </FormControl>
        </Cell>
        <Cell size={isCoordenador ? 4 : 3} style={styles.cell}>
          <EquipeResponsavelField
            name={path.equipeResponsavel}
            equipe={acesso?.equipe}
            unidadeSaudeId={values?.unidadeResponsavelId}
            hasAccessToTodasEquipes={isCoordenador}
            placeholder='Selecione uma equipe'
            required={isCoordenador}
            onClear={() => onClearField(form, path.equipeResponsavel.absolutePath())}
            onChange={handleEquipeResponsavelChange}
          />
        </Cell>
        <Cell size={3} style={styles.cell}>
          <MicroareaSelectField
            name={path.microareas}
            label='Microárea'
            metaEquipe={path.equipeResponsavel}
            placeholder='Todas as microáreas'
            disabled={isCoordenador && !values?.equipeResponsavel}
          />
        </Cell>
        <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.problemasECondicoes}>
          <Cell size={12}>
            <Heading level={2}>Problemas e condições</Heading>
            <Alert
              inline
              type='info'
              styles={{
                wrapper: css`
                  margin-top: 0.5rem;
                `,
                container: css`
                  margin-right: 4.5rem;
                `,
              }}
            >
              A busca retorna apenas cidadãos que possuem todas as condições selecionadas e ativas na lista de problemas
              ou avaliadas no SOAP e fichas de atendimento individual.
            </Alert>
          </Cell>
        </CheckPermission>
        <Cell size={12}>
          <Grid>
            <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.problemasECondicoes}>
              <Cell size={12}>
                <GrupoCondicaoBuscaRapida
                  path={path}
                  value={values}
                  onChange={form.change}
                  handleClearCheckbox={handleClearCheckbox}
                />
              </Cell>
              <Cell
                size={6}
                style={css`
                  margin-top: 0.5rem;
                `}
              >
                <GrupoCondicaoSelectField
                  label='Grupos de condições prioritários'
                  name={path.grupoCondicao.absolutePath()}
                  multiple
                  placeholder='Selecione grupos de condições prioritários'
                  onChange={handleOnChangeGrupoCond}
                />
              </Cell>
              <Cell
                size={6}
                style={css`
                  margin-top: 0.5rem;
                `}
              >
                <CiapCidSelectField
                  label='CIAP2 e CID10'
                  name={path.problemasCondicoes.absolutePath()}
                  multiple
                  placeholder='Selecione outros CIAP2 e CID10'
                  onChange={handleChangeCiapCid}
                />
              </Cell>
              <Cell size={12}>
                <CheckboxField
                  name={path.ativoListaProblema.absolutePath()}
                  label='Buscar apenas problemas / condições ativas na lista de problemas e condições.'
                  disabled={canCheckApenasProblemasCondicoesAtivas}
                />
              </Cell>
            </CheckPermission>
            <Cell size={12}>
              <Heading level={2}>Informações do cidadão</Heading>
            </Cell>
            <Cell size={6}>
              <SexoIdentidadeGeneroButtonGroup onChange={form.change} meta={path} />
            </Cell>
            <Cell size={6}>
              <DateRangeField
                name={path.periodoUltimoAtendimento.absolutePath()}
                label='Período do último atendimento individual'
                startPlaceholder='Data inicial'
                finalPlaceholder='Data final'
                maxDate={now}
              />
            </Cell>
            {IVCF_ACOMPANHAMENTO_CONDICOES_ENABLED && (
              <CheckPermission permission={Permissions.acompanhamentos.condicoesDeSaude.ivcf}>
                <Fragment>
                  <Cell size={12}>
                    <Heading level={4}>Habilitar filtros:</Heading>
                  </Cell>
                  <Cell size={10} style={styles.ivcfCell}>
                    <IvcfFilterGroup onChange={form.change} meta={path} values={values} />
                  </Cell>
                  <Cell size={2} />
                </Fragment>
              </CheckPermission>
            )}
            <Cell size={8}>
              <FaixaEtariaFilterGroup
                change={form.change}
                meta={path}
                faixaEtariaFilter={values?.tipoFaixaEtaria}
                isBotaoIvcfHabilitado={values?.isBotaoIvcfAtivo}
              />
            </Cell>
            <Cell size={4} />
          </Grid>
        </Cell>
      </Grid>
      <SearchFooter
        handleSubmit={handleOnSubmit}
        onCleanFilters={form.reset}
        isCoordenador={isCoordenador}
        equipeResponsavelId={values.equipeResponsavelId}
      />
    </VFlow>
  )
}

const styles = {
  cell: css`
    margin-top: 1rem;
  `,
  ivcfCell: css`
    padding-top: 0;
  `,
}
