import { Button, Icon } from 'bold-ui'
import { PrivateRoute } from 'components/route'
import { subDays } from 'date-fns'
import arrayMutators from 'final-form-arrays'
import { useMedicamentoUsoBarraLateralQuery, usePossuiTratamentoConcluidoQuery } from 'graphql/hooks.generated'
import { ReceitaMedicamento } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty } from 'lodash'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { Switch } from 'react-router-dom'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util-atendimentoIndividual'
import MedicamentosAtivosConcluidosModal from 'view/atendimentos/detail/components/modals/medicamentos/MedicamentosAtivosConcluidosModal'
import { CidadaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'

import { grupoCboPrescricao } from '../../plano/acessos'
import EncaminharPrescricaoModal from '../../plano/prescricao-medicamento/modal/EncaminhamentoPrescricaoModal'
import { MedicamentoFormModel } from '../../plano/prescricao-medicamento/model-prescricao'
import { convertReceitaMedicamentoToMedicamento } from '../../plano/prescricao-medicamento/utils/converter-prescricao'
import { ProntuarioCard } from '../ProntuarioCard'
import { concatenarOrigemTagAnalytics } from '../util/analyticsUtils'
import { MedicamentosCard } from './MedicamentosCard'

export interface MedicamentosSectionProps {
  prontuarioId: ID
  dataReferencia: Instant
  isCidadaoIdoso: boolean
  mostrarInativos?: boolean
  editable?: boolean
  isAtendimentoProcedimentos?: boolean
  isAtendimentoObservacao?: boolean
  atendimentoProfissionalId?: ID
  atendimentoId?: ID
  isRegistroTardio: boolean
  cidadaoFolhaRosto?: CidadaoFolhaRosto
}

enum medicamentosTooltipEnum {
  VER_TODOS = 'Ver medicamentos',
  NENHUM_REGISTRADO = 'Nenhum medicamento registrado',
  VER_TRATAMENTO_CONCLUIDO = 'Ver medicamentos com tratamento concluído',
}

const DIAS_MOSTRAR_INATIVOS = 90

export function MedicamentosSection(props: MedicamentosSectionProps) {
  const {
    prontuarioId,
    isCidadaoIdoso,
    dataReferencia,
    mostrarInativos = false,
    editable = false,
    isAtendimentoProcedimentos = false,
    isAtendimentoObservacao = false,
    atendimentoProfissionalId,
    atendimentoId,
    isRegistroTardio,
    cidadaoFolhaRosto,
  } = props

  const history = useHistory()
  const match = useRouteMatch()
  const [canRender, setCanRender] = useState(false)
  const [tooltip, setTooltip] = useState(medicamentosTooltipEnum.NENHUM_REGISTRADO)

  const modalUrl = `${match.path}/medicamentos-ativos-concluidos`

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)
  const { analytics } = useFirebase()

  const handleClick = () => {
    history.push({
      pathname: modalUrl,
      state: {
        prontuarioId: prontuarioId,
        dataAtendimento: dataReferencia,
      },
    })
    const tag = concatenarOrigemTagAnalytics('acessar_card_medicamentos', match.url)
    analytics.logEvent(tag)
  }

  const {
    data: { listaMedicamento: listaMedicamentosAtivos },
    loading: loadingAtivos,
  } = useMedicamentoUsoBarraLateralQuery({
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
        dataAtendimento: dataReferencia,
        usoContinuo: false,
        ativo: true,
        pageParams: { unpaged: true },
      },
    },
  })

  const dataDesdePrescricao = Number(subDays(dataReferencia, DIAS_MOSTRAR_INATIVOS))

  const {
    data: { listaMedicamento: listaMedicamentosInativos },
    loading: loadingInativos,
  } = useMedicamentoUsoBarraLateralQuery({
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
        dataAtendimento: dataReferencia,
        usoContinuo: false,
        ativo: false,
        dataMinimaFimTratamento: dataDesdePrescricao,
      },
    },
    skip: !mostrarInativos,
  })

  const hasMedicamentosAtivos = !isEmpty(listaMedicamentosAtivos?.content)

  const existemMedicamentos = hasMedicamentosAtivos || !isEmpty(listaMedicamentosInativos?.content)

  const {
    data: { possuiTratamentoConcluido },
    loading: loadingTratamentoConcluido,
  } = usePossuiTratamentoConcluidoQuery({
    variables: { input: { prontuarioId, editAtendProfId, dataAtendimento: dataReferencia } },
    skip: hasMedicamentosAtivos,
  })

  useEffect(() => {
    setCanRender(existemMedicamentos)
    const tooltip = possuiTratamentoConcluido
      ? medicamentosTooltipEnum.VER_TRATAMENTO_CONCLUIDO
      : existemMedicamentos
      ? medicamentosTooltipEnum.VER_TODOS
      : medicamentosTooltipEnum.NENHUM_REGISTRADO
    setTooltip(tooltip)
  }, [canRender, possuiTratamentoConcluido, existemMedicamentos])

  const medicamentosAtivos: MedicamentoFormModel[] = useMemo(
    () =>
      listaMedicamentosAtivos?.content?.map((receita) =>
        convertReceitaMedicamentoToMedicamento(
          receita as ReceitaMedicamento,
          receita.atendProf.versaoAnterior,
          false,
          receita.dtInicioTratamento
        )
      ),
    [listaMedicamentosAtivos]
  )

  return (
    <Fragment>
      <Route
        path={modalUrl}
        render={() => (
          <MedicamentosAtivosConcluidosModal
            editable={editable}
            prontuarioId={prontuarioId}
            atendimentoProfissionalId={atendimentoProfissionalId}
            dataAtendimento={dataReferencia}
            isAtendimentoProcedimentos={isAtendimentoProcedimentos}
            isAtendimentoObservacao={isAtendimentoObservacao}
          />
        )}
      />
      <Form
        onSubmit={() => {}}
        mutators={{ ...arrayMutators }}
        render={() => (
          <Fragment>
            {!isRegistroTardio && !isEmpty(medicamentosAtivos) && (
              <Switch>
                <PrivateRoute
                  path={`${match.url}/medicamentos-ativos/imprimir`}
                  permission={grupoCboPrescricao.adicionar}
                >
                  <EncaminharPrescricaoModal
                    isReimpressao
                    medicamentos={medicamentosAtivos.filter((medicamento) => !medicamento.registroManual)}
                    atendimentoId={atendimentoId}
                    cidadaoFolhaRosto={cidadaoFolhaRosto}
                  />
                </PrivateRoute>
              </Switch>
            )}

            <ProntuarioCard
              title={mostrarInativos ? 'Medicamentos' : 'Medicamentos em uso'}
              content={
                !isRegistroTardio &&
                !isEmpty(medicamentosAtivos) && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      history.push(`${match.url}/medicamentos-ativos/imprimir`)
                    }}
                    skin='ghost'
                    size='small'
                  >
                    <Icon icon='printerOutline' />
                  </Button>
                )
              }
              tooltip={tooltip}
              onClick={handleClick}
            >
              <MedicamentosCard
                listaMedicamentosAtivos={listaMedicamentosAtivos?.content}
                listaMedicamentosInativos={listaMedicamentosInativos?.content}
                loading={loadingAtivos || loadingInativos}
                loadingTratamentoConcluido={loadingTratamentoConcluido}
                isCidadaoIdoso={isCidadaoIdoso}
                canRender={canRender}
                mostrarInativos={mostrarInativos}
              />
            </ProntuarioCard>
          </Fragment>
        )}
      />
    </Fragment>
  )
}
